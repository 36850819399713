import { HttpClient, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import {Feature, Image, PluginClass, Point, Utils} from '../../';
import { IContainer, IContentChild, ILightBox, IMapResult, IPluginInterface, IPopup, ISearchResult } from '../../interfaces';
import {LatLng} from 'leaflet';

@Component({
  selector: 'single_popup',
  templateUrl: 'single_result_popup.component.html',
  styleUrls: ['single_result_popup.component.less']
})
export class SingleResultPopup extends PluginClass implements ISearchResult, IContentChild {
  feature:Feature;
  parentComponent:IContainer;
  features:Feature[] = [];
  features_to_display:any[] = [];
  allowFullInfo = true;

  protected mapResultsPlugs:IMapResult[] = [];
  protected popupPlugins:IPopup[] = [];
  protected layerInfoPlugins:ISearchResult[] = [];
  protected lightBoxPlugins:ILightBox[] = [];

  constructor(private httpClient:HttpClient) {
    super();
  }

  addInterface(name:string, pi:IPluginInterface):void {
    switch (name) {
      case 'MapResults':
        this.mapResultsPlugs.push(pi as IMapResult);
        break;
      case 'Popup':
        this.popupPlugins.push(pi as IPopup);
        break;
      case 'ShowResult':
        this.layerInfoPlugins.push(pi as ISearchResult);
        break;
      case 'LightBox':
        this.lightBoxPlugins.push(pi as ILightBox);
        break;
      default:
        console.error(`Компонент ${(this.constructor as any).name} не обрабатывает вход ${name}`);
        break;
    }
  }

  removeInterface(name:string):void {
    switch (name) {
      case 'MapResults':
        this.mapResultsPlugs = [];
        break;
      case 'Popup':
        this.popupPlugins = [];
        break;
      case 'ShowResult':
        this.layerInfoPlugins = [];
        break;
      case 'LightBox':
        this.lightBoxPlugins = [];
        break;
    }
  }

  enable() {}

  disable() {}

  // Подсветить объект, поставить маркер, открыть попап
  setFeatures(features:Feature[], showPlace:boolean, layers:any[], ...options:any[]) {
    if (!features.length) {
      return;
    }

    const featuresByLayer = features.reduce((acc, value) => {
      const existingLayer = acc.find(item => item.id === value.layer.id);
      if (existingLayer) {
        existingLayer.features.push(value);
        existingLayer.objects++;
      } else {
        acc.push({
          name: value.layer.name,
          id: value.layer.id,
          objects: 1,
          features: [value]
        });
      }
      return acc;
    }, []);

    this.feature = features[0];
    this.features = features;

    // подсветка области поиска, если результатов несколько
    let searchAreaFeature:Feature;
    let altStyle = false;
    if (options.length && this.features.length > 1) {
      const geoJSON = options[0];
      searchAreaFeature = new Feature();
      searchAreaFeature.geometry = geoJSON;
      altStyle = true;
    }

    const featureToDisplay = searchAreaFeature || this.feature;

    this.mapResultsPlugs.forEach(mapPlugin => {
      let popupLatLng;
      let isSinglePoint:boolean = this.feature.geometry.type === 'Point';
      isSinglePoint = isSinglePoint || (this.feature.geometry.type === 'MultiPoint' && this.feature.geometry.coordinates.length === 1);
      if (options[1] && !isSinglePoint) {
        popupLatLng = options[1] as Point;
      } else if (featureToDisplay.geometry) {
        popupLatLng = mapPlugin.getFeatureCenter(featureToDisplay);
      }
      if (popupLatLng) {
        this.popupPlugins.forEach(plugin => {
          plugin.showPopup(popupLatLng, false);
        });
        if (showPlace) {
          //Показать квадратик поиска
          mapPlugin.showFeature([featureToDisplay], altStyle).then(data => {
            if (this.feature.layer.popupImage) {
              this.getImageForFeature(this.feature);
            }
          });
        }
        if (options[0] && options[0].fitExtent) {
          mapPlugin.goToFeature(this.feature);
        }
      }
    });

    this.features_to_display = featuresByLayer.length > 3 ? featuresByLayer.slice(0, 3) : featuresByLayer;
  }

  getFeatures() {
    return this.features;
  }

  updateFeatures() {}

  // приблизиться к объекту
  gotoFeature(feature:Feature) {
    this.mapResultsPlugs.forEach(plug => {
      plug.showFeature([feature]);
      plug.goToFeature(feature);
    });
  }

  showInfo(feature:Feature) {
    this.layerInfoPlugins.forEach(plugin => {
      plugin.showInfo(feature);
    });

    this.popupPlugins.forEach(plugin => {
      plugin.closePopup();
    });
  }

  editInfo(feature:Feature) {}

  clearResults() {
    this.feature = null;
    this.features = [];

    // Скрыть попап
    this.popupPlugins.forEach(plugin => {
      plugin.closePopup();
    });

    // Очистить карту
    this.mapResultsPlugs.forEach(plugin => {
      plugin.clearResult();
    });
  }

  activate() {}

  deactivate() {
    this.mapResultsPlugs.forEach(plugin => {
      plugin.clearResult();
    });
  }

  showFeatureInfo(feature:Feature) {
  }

  getNumeralEnding(num:number) {
   return Utils.getNumeralEnding(num);
  }

  protected getImageForFeature(feature:Feature) {
    const pk = feature.properties[feature.layer.pk];

    const url = 'feature_image/';
    const params:HttpParams = new HttpParams().set('pk', pk).set('layer_id', `${feature.layer.id}`);

    this.httpClient.get(url, { params }).subscribe((data:any) => {
      feature.images = data.images.map(image => new Image(image));
    });
  }

  private showLayerResults(layerFeatures:any) {
    this.layerInfoPlugins.forEach(plugin => {
      if (layerFeatures.features.length === 1) {
        plugin.showFeatureInfo(layerFeatures.features[0]);
      } else {
        plugin.setFeatures(layerFeatures.features, true);
      }
    });
  }

  private showAll() {
    this.layerInfoPlugins.forEach(plugin => {
      plugin.setFeatures(this.features, true);
    });
    this.parentComponent.close();
  }
}
